<template>
  <div
    class="project-map-inner"
    :class="{'customHeight': customHeight}"
  >
    <div
      id="yandex-map-block-object-map-block"
      ref="refYandexMap"
      class="object-map-block-map"
    />
  </div>
</template>

<script setup lang="ts">
// @ts-nocheck
import type { MapSettings } from 'vue-yandex-maps/src/types';
import { loadYmap } from 'vue-yandex-maps';
import { ref } from '#imports';
import type { ComputedRef } from '#imports';
import type { ICategory } from '#sitis/internal/controllers/categories/models/Category';

export type MapPoint = {
	id: string,
	name: string,
	subtitle: string,
	lat: number,
	lon: number,
	variant: string,
	isOffice?: boolean
}

const { projects, officePoints, activeProject, customHeight } = defineProps<{

	projects: ICategory[];
	officePoints: MapPoint[];
	activeProject: { id: string | number };
	customHeight?: boolean;
}>();

const emits = defineEmits<{
	changeActiveProject: [project: { id: string | number }]
}>();

const {yandexMapApiKey} = useRuntimeConfig().public

const points: ComputedRef<MapPoint[]> = computed(() => {
  const projectPoints: MapPoint[] = projects.map((item) => ({
    id: item.id,
    name: item.name,
    subtitle: `${item.dynamicFields?.street || ''}, ${item.dynamicFields?.number || ''}`,
    variant: 'primary',
    lat: item.dynamicFields?.lat,
    lon: item.dynamicFields?.lon
  }));

  return [...projectPoints, ...officePoints];
});

const settings: MapSettings = {
  apiKey: yandexMapApiKey,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};

const refYandexMap = ref<HTMLElement | string>('');
const placemarkDOMRef = ref<HTMLElement | string>('');
let yandexMap: ymaps.Map;

const yandexMapSetClusters = () => {
  const clusterCustom = ymaps.templateLayoutFactory.createClass('' +
    '<div class="clusterIcon">' +
    '<span>{{ properties.geoObjects.length }}</span>' +
    '</div>');

  return new ymaps.Clusterer({
    clusterDisableClickZoom: false,
    clusterNumbers: [50],
    clusterIconLayout: clusterCustom,
    clusterIconShape: {
      type: 'Rectangle',
      coordinates: [[0, 0], [46, 46]]
    }
  });
};

const setUpPoints = () => {
  const cluster = yandexMapSetClusters();

  points.value.forEach((point, index) => {
    if (!point.lat || !point.lon) {
      return;
    }
    const isActive = point.id === activeProject.id;

    let className = 'map-point-content';

    if (point.isOffice) {
      className += ' office';
    }

    if (isActive) {
      className += ' --active';
    }

    const variant = point?.variant || 'primary';

    const MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
      `<div id="placemark-point-${index}" class="${className} ${variant}">
        <div class="icon-main"></div>
        <div class="content">
          <span class="name">${point.name}</span>
          <span class="area">${point.subtitle}</span>
        </div>
      </div>`
    );

    const myPlacemarkWithContent = new ymaps.Placemark(
      [point.lat, point.lon],
      { clusterName: 'clusterName' },
      {
        iconLayout: 'default#imageWithContent',
        iconImageHref: '',
        iconImageSize: isActive ? [54, 78] : [38, 54],
        iconImageOffset: isActive ? [-26, -74] : [-19, -54],
        iconContentLayout: MyIconContentLayout
      }
    );

    const updatePlacemarkDOMElement = (isSetAdditionalClass: boolean = false) => {
      placemarkDOMRef.value = document.getElementById(`placemark-point-${index}`);

      if (placemarkDOMRef) {
        if (isSetAdditionalClass) {
          placemarkDOMRef.value.classList.add('--hover');
        } else {
          placemarkDOMRef.value.classList.remove('--hover');
        }
      }
    }

    myPlacemarkWithContent.events
      .add('mouseenter', () => {
        updatePlacemarkDOMElement(true)
      })
      .add('mouseleave', () => {
        updatePlacemarkDOMElement(false)
      })
      .add('click', () => {
        emits('changeActiveProject', {id: point.id});
      })

    cluster.add(myPlacemarkWithContent);
  });

  yandexMap.geoObjects.add(cluster);
};

const removeMarkers = () => {
  if (!yandexMap) {
    return;
  }
  yandexMap?.geoObjects?.removeAll();
};

const yandexMapInit = async () => {
  await loadYmap(settings);
  await ymaps.ready(() => {
    const centerCoords = officePoints.length ? [officePoints[0].lat, officePoints[0].lon] : [55.019074, 82.917399];
    yandexMap = new ymaps.Map(refYandexMap.value, {
      center: centerCoords,
      zoom: 11,
      behaviors: ['hover', 'drag', 'dblClickZoom', 'multiTouch']

    }, {
      maxZoom: 20
    });

    setUpPoints();
  });
};

onMounted(async () => {
  await yandexMapInit();
});

watch(() => activeProject, () => {
  if (!yandexMap) {
    return;
  }

  removeMarkers();
  setUpPoints();
});

watch(() => officePoints, () => {
  if (!yandexMap) {
    return;
  }

  removeMarkers();
  setUpPoints();
});

</script>

<style lang="scss">
@import "@/assets/scss/media";

.project-map-inner {
  height: 355px;
  width: 100%;
  border-radius: 22px;
  overflow: hidden;
}

.customHeight {
  height: 702px;
}

.object-map-block-map {
  height: 100%;
  position: relative;

  & .yandex-container {
    height: 100%;
  }

  .map-point-content {
    position: relative;
    min-width: 38px;

    &.office {
      .icon-main {
        width: 38px;
        height: 54px;
        background-image: url("@/assets/img/svg/map/map-pin-point-secondary.svg") !important;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .icon-main {
      width: 38px;
      height: 54px;
      background-image: url("@/assets/img/svg/map/map-pin-point.svg") !important;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .content {
      display: none;
      flex-direction: column;
      background: #FFF;
      padding: 6px 12px;
      box-sizing: border-box;
      white-space: nowrap;
      position: absolute;
      top: 0;
      left: calc(100% + 10px);
      border: 1px solid #E2DDE9;
      box-shadow: 7px 15px 30px rgb(60 29 109 / 12%);
      border-radius: 10px;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #3C1D6D;
      text-align: left;
      margin-bottom: 4px;

      br {
        display: none;
      }
    }

    .area {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #3C1D6D;
      text-align: left;
    }

    &.--active {
      min-width: 56px;

      .icon-main {
        width: 54px;
        height: 78px;
        background-image: url("@/assets/img/svg/map/map-pin-point-big.svg") !important;
      }

      &.office {
        .icon-main {
          width: 54px;
          height: 78px;
          background-image: url("@/assets/img/svg/map/icon_pin-ekb.svg") !important;
        }
      }
    }

    &.--hover {
      .content {
        display: flex;
      }
    }
  }

  .map-point-content.secondary {
    .icon-main {
      background-image: url("@/assets/img/svg/map/map-pin-point-secondary.svg") !important;
    }
  }
}

@include media('lg') {
  .object-map-block-map {
    .map-point-content {
      .name {
        max-width: 320px;
        white-space: initial;
      }
    }
  }
}

@include media('sm') {
  .customHeight {
    height: 360px;
  }
}

</style>
