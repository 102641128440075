import type { Ref } from "#imports";
import type { MapPoint } from "~/components/map/Map.vue";
import { storeToRefs } from "pinia";
import { appConfigStore as useAppConfigStore } from "#sitis/stores/modules/app-store";

export const getSalesOfficePoint = async (officePoints: Ref<MapPoint[]>) => {
  const appConfigStore = useAppConfigStore();
  const { vars } = storeToRefs(appConfigStore);
  const addressOffice = vars?.value['Адрес офиса продаж']

  if (!addressOffice) {
    return;
  }

  const addresses: string[] = addressOffice
    .split('<br/>')
    .filter((item: string) => item);

  officePoints.value = await Promise.all(addresses.map(async (address: string, index: number) => {
    const [lat, lon]: [number, number] = [
      Number(vars.value?.lat) || 0,
      Number(vars.value?.lon) || 0,
    ]

    return {
      id: `office-sales-${index}`,
      name: 'Офис продаж',
      subtitle: address,
      variant: 'primary',
      lat: lat,
      lon: lon,
      isOffice: true
    };
  }));
};
